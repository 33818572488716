const en = {
  enter_phone: "Enter your mobile phone",
  country_select: "Select Country",
  select: "Select",
  mob_num: "Mobile Number",
  next: "Next",

  code_validation: "Code Validation",
  enter_otp_message: "Please enter the 4 digit code sent to your mobile number",
  resend_code_message: "Didn’t get the code? Resend in ",
  sec: "sec",
  resend: "Resend",
  otp_success: "Verification Code send successfully",
  otp_error: "Error resending Verification Code",
  login_warn:"Please login !!!",

  first_name: "First Name",
  last_name: "Last Name",
  date_of_birth: "Date of Birth",
  address: "Address",
  email: "Email",
  phone_number: "Phone Number",
  payment_preference: "Payment Preference",
  cash: "Cash",
  credit_card: "Credit Card",
  paypal: "Paypal",
  bank: "Bank Transfer",
  personal_info: "Personal Information",
  enter_here: "Enter here",
  country: "country",
  profile: "Manage Profile",
  update: "Update",

  billboard_location: "Billboard Location",
  installation_date: "Installation Date",
  running_ads: "Running Advertisements",
  upload_ads: "Upload Ads",
  no_data_available: "No data available",
  ad_name_title: "Ad name will come here",
  billboard: "Billboard",
  start_date: "Start Date",
  end_date: "End Date",
  cost: "Cost",
  ad_exposure: "AD exposures",
  billboard_offline:
    "This billboard is currently offline, preventing the uploading of new ads. Our technical team is actively addressing the issue",

  ad_performance_tracking: "Precise Ad Performance Tracking",
  ad_performance_tracking_description:
    "Measure ad impact just like Facebook. Know exposure, set campaign prices, and pay post-campaign",
  effective_ads_transparent_pricing: "Effective Ads, Transparent Pricing",
  effective_ads_transparent_pricing_description:
    "Ensure ad quality, set prices based on exposure. Pay once your campaign wraps up",
  seamless_ad_experience: "Seamless Ad Experience",
  seamless_ad_experience_description:
    "Effortless campaigns with measurable results. Pay only after your campaign concludes",

  ad_policy: "Advertising Policy",
  ad_content_approval: "Content Approval",
  ad_content_approval_body:
    "We are committed to maintaining a safe and respectful advertising environment. Only video content that complies with the law will be approved. Please refrain from uploading advertisements featuring prohibited content such as drugs, alcohol, or political material.",
  ad_video_requirements: "Video Requirements",
  ad_video_requirements_body:
    "To ensure the best display quality, only high-quality video files will be approved. Videos should have a 1:2 ratio structure (length/width) or be in a TIKTOK video file format.",
  ad_campaign_cost: "Campaign Cost",
  ad_campaign_cost_body:
    "The cost of your campaign will be displayed after you upload your advertisement file. You have the flexibility to adjust the campaign's duration or video length to modify the campaign cost.",
  ad_cost_calculation: "Cost Calculation",
  ad_cost_calculation_body:
    " Campaign costs are determined based on various factors, including video length, billboard exposure, and campaign duration. Please note that longer videos on billboards with high exposure will result in higher campaign costs.",
  ad_additional_note: "Additional Note",
  ad_additional_note_body:
    " Please familiarize yourself with our advertising policy to ensure your campaign complies with our guidelines. We are dedicated to delivering effective and responsible billboard advertising experiences for all.",
  accept_continue: "Accept & Continue",

  ad_name: "Ad Name",
  campaign_start_date: "Campaign Start Date",
  campaign_end_date: "Campaign End Date",
  automatic_calculation: "Automatic Calculation",
  attach_video: "Attach a Video",
  upload_ad: "Upload ad",
  video_resolution_message:
    "Video Resolution is incorrect should be in ratio 9:16",
  video_length_message: "Video Length is greater than 30 seconds.",
  video_size_message: "Video size should be greater 1MB",
  video_requirements: "Video Requirements",
  video_files_only: "Upload only video files (.mp4, .mov).",
  limit_30_seconds: "Limit videos to 30 seconds or less.",
  video_resolution:
    "The video should have a resolution of 1:2 PX Or TikTok video.",
  ensure_compliance: "Ensure videos comply with company policy.",
  ad_created_success: "Created new ad successfully",
  ad_created_failure: "Unable to create the ad",
  thanks: "Thankyou!!",
  ad_upload_success: "Your ads uploaded successfully",

  dashboard: "Dashboard",
  pending_ads: "Pending Ads",
  approved_ads: "Approved Ads",
  active_ads: "Active Ads",
  expired_ads: "Expired Ads",

  scanner: "Scanner",

  my_profile: "My Profile",
  logout: "Log out",

  home: "Home",

  validation: {
    phone: "Phone number is required",
    firstName: "First Name is required",
    lastName: "Last Name is required",
    dob: "Date of birth is required",
    city: "City is required",
    email_Invalid: "Invalid email address",
    email: "Email is required",
    required: "This field is required",
    invalid_date_format: "Invalid date format",
    start_date_required: "Campaign Start date is required",
    end_date_required: "Campaign End date is required",
    end_date_after_start_date:
      "Campaign End date should be after the Start date",
  },
};
module.exports = en;
