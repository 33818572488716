import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";

import LineChart from "./area-chart";
import AccordingToggle from "./accordingToggle";
import { useCallback, useEffect, useState } from "react";

import Swal from "sweetalert2";
import docomentIcon from "../icon/document-upload.svg";
import Location from "../icon/location.svg";
import CustomButton from "../commanButton";
import IconBg from "../../../images/ledprosync_LOGO-05.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getBillboard } from "../../../component/billboard/detail/billboard-detail.api";
import moment from "moment";
import {
  GetAllSummary,
  GetBillboardAds,
  GetSummaryByDate,
} from "../../../api/auth";
import { getCatalogueApi } from "../../../component/home/home-tab/home-tab.api";
import { FidgetSpinner, RotatingLines } from "react-loader-spinner";
import { Loader } from "../Loader";
import { generateOrderID } from "../../../utils/helper";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  // state management
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [toogleState, setToogleState] = useState(null);
  const [billboardStatus, setbillboardStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [Id, setId] = useState("");
  const [name, setName] = useState("");

  const { billboardId } = useParams();
  const [billboard, setBillboard] = useState({
    data: null,
    error: false,
  });
  const [graph, setgraph] = useState({
    data: null,
    error: false,
  });
  const [ads, setads] = useState({
    data: null,
    error: false,
  });

  useEffect(() => {
    setBillboard({
      data: null,
      error: false,
    });
    setLoading(true);
    getBillboard(billboardId)
      .then(({ data }) => {
        if (data.status === "success") {
          setBillboard((prev) => ({ ...prev, data: data.data }));
          setName(data?.data?.billboardName);

          // Check billboard is online or not
          if (data?.data?.status === "offline") {
            // console.log("\n\n<Dashboard> data?.data?.status", data?.data);
            setbillboardStatus(false);
          }
        } else {
          setBillboard((prev) => ({ ...prev, error: true }));
        }
      })
      .catch(() => {
        setBillboard((prev) => ({ ...prev, error: true }));
      });
  }, [billboardId]);

  useEffect(() => {
    if (name) {
      const date = new Date();
      date.setFullYear(2023);
      // console.log("<Dashboard> date", date);
      const currentMonth = new Date().getMonth();
      const previousYear = new Date().getFullYear() - 1;
      const skip = (currentMonth + 1) * 10;
      // return

      GetAllSummary()
        .then(async ({ data }) => {
          const response = await GetSummaryByDate(date, skip, name);
          // const { name } = data;
          // console.log("\n<Dashboard>previousSummary", response);
          const previousSummary = response?.data?.documents;
          const previousMonths = Object.keys(
            previousSummary[name]?.monthly
              ? previousSummary[name]?.monthly
              : previousSummary[
                  Object?.keys(previousSummary)[
                    Object?.keys(previousSummary).length - 3
                  ]
                ]?.monthly
          ).slice(currentMonth + 1);
          const previousValues = previousSummary[name]?.monthly
            ? Object.values(previousSummary[name]?.monthly).slice(
                currentMonth + 1
              )
            : new Array(previousMonths.length).fill(0);

          // console.log(
          //   "\n<Dashboard>previousSummary",
          //   previousMonths,
          //   previousValues
          // );
          const monthlyData = data?.documents[name]?.monthly;
          const months = Object.keys(monthlyData).slice(0, currentMonth + 1);
          const values = Object.values(monthlyData).slice(0, currentMonth + 1);
          const totalMonths = [...previousMonths, ...months];
          const totalValues = [...previousValues, ...values];
          // console.log("\n<Dashboard>Total Summary", totalMonths, totalValues);
          setgraph({ data: { months: totalMonths, values: totalValues } });
        })
        .catch((resp) => {
          alert("Error", resp);
          console.log("\n<GetAllSummary> Error", resp);
        });
      GetBillboardAds(name)
        .then(({ data }) => {
          console.log("\n\n\n<Dashboard> data", data);
          const updatedData = data?.data?.filter(
            (ele) =>
              ele.status === "approved" && ele.subStatus === "VIDEO_UPLOADED"
          );
          setads({ data: updatedData });
        })
        .catch((err) => {
          alert(err);
        });

      localStorage.setItem("billboardName", name);
      localStorage.setItem("billboardId", billboardId);
    }
  }, [name]);

  useEffect(() => {
    if (billboard?.data && ads?.data && graph) {
      setLoading(false);
    }
    // console.log("\n<Dashboard>graph", graph?.data);

    localStorage.setItem(
      "adExposure",
      Math.round(billboard?.data?.spots_per_ad_per_day)
    );
  }, [billboard, ads, graph]);

  useEffect(() => {
    setId(generateOrderID());
  }, []);

  const handleClick = () => {
    Swal.fire({
      background: "rgb(0 0 0 / 84%)",
      title: `<div class='modalix'>${t("billboard_offline")}</div>`,

      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      showConfirmButton: false,
      width: "450px",
    });
  };

  // useState(() => {
  //   if (!billboardStatus) {
  //     handleClick();
  //     console.log("\n\n<Dashboard> Billboard Status", billboardStatus);
  //   }
  // }, [billboard]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          component={"main"}
          sx={{
            height: "100%",
            p: 3,
            position: "relative",
          }}
        >
          <Card
            sx={{
              border: "1px solid rgba(255, 255, 255, 0.15)",
              borderRadius: "16px",
              mb: 2,
              background:
                "linear-gradient(97deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.03) 100%)",
            }}
          >
            <CardContent
              sx={{ "&:last-child": { pb: 2 } }}
              dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
            >
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#FBC515",
                      fontWeight: "700",
                    }}
                  >
                    {t("billboard_location")}
                  </Typography>
                  <Typography
                    component={Stack}
                    direction={"row"}
                    alignItems={"center"}
                    spacing={0.5}
                    sx={{
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      mt: 0.5,
                    }}
                  >
                    <img alt="" src={Location} />
                    <span>
                      {billboard?.data?.address ? billboard?.data?.address : ""}
                    </span>
                  </Typography>
                </Box>
                <Avatar
                  alt=""
                  src={IconBg}
                  sx={{
                    width: "65px",
                    height: "65px",
                    // background: "#fff",
                    padding: "10px",
                  }}
                />
              </Stack>
              <Divider sx={{ borderColor: "#003E44", my: 1 }} />
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <Typography
                  sx={{ fontSize: "14px", color: "#FBC515", fontWeight: "700" }}
                >
                  {t("installation_date")}
                </Typography>
                <Box
                  sx={{ fontSize: "14px", fontWeight: "500", color: "#fff" }}
                >
                  {billboard?.data?.installationDate
                    ? moment(billboard?.data?.installationDate).format(
                        "MMM DD YYYY"
                      )
                    : t("no_data_available")}
                </Box>
              </Stack>
            </CardContent>
          </Card>

          {graph?.data ? (
            <Card
              sx={{
                border: "1px solid rgba(255, 255, 255, 0.15)",
                borderRadius: "16px",
                mb: 2,
                background:
                  "linear-gradient(97deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.03) 100%)",
              }}
            >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
                <LineChart graph={graph?.data} />
              </CardContent>
            </Card>
          ) : (
            <FidgetSpinner />
          )}
          <Typography
            dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
            component={Stack}
            direction={"row"}
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ color: "#FABE2C", fontSize: "14px", fontWeight: 700 }}
          >
            <span>{t("running_ads")}</span>
            <Box component={"span"} sx={{ color: "#D9D9D9", fontWeight: 500 }}>
              {ads?.data?.length}
            </Box>
          </Typography>
          <Box
            sx={{ my: 2 }}
            dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
          >
            <Stack direction={"column"} spacing={2}>
              {ads?.data?.map((item) => (
                <AccordingToggle
                  key={item}
                  toogleValue={toogleState === item}
                  handleToogle={() =>
                    setToogleState(toogleState === item ? null : item)
                  }
                  value={item}
                  adExposure={
                    item?.adExposures
                      ? Math.round(
                          item.adExposures?.reduce(
                            (acc, exposure) =>
                              acc +
                              (exposure?.exposures?.[name]
                                ? exposure?.exposures?.[name]
                                : 0),
                            0
                          )
                        )
                      : null
                  }
                  // billboardAd={billboard?.data?.address}
                  t={t}
                />
              ))}
            </Stack>
          </Box>

          {/* Conditional rendering for no data available message */}
          {ads?.data?.length === 0 && (
            <Typography
              dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
              sx={{ color: "#fff", fontSize: "16px", fontWeight: 600 }}
            >
              {t("no_data_available")}
            </Typography>
          )}
          {/* User Offline Modal */}
          {/* <Box sx={{ textAlign: "right" }}>
          <Box
            component={"span"}
            sx={{ cursor: "pointer", color: "#fff" }}
            onClick={handleClick}
          >
            Modal open
          </Box>{" "}
        </Box> */}
          <Box sx={{ mt: 7, mb: 5 }}>
            {/* <Link to={`/user/${Id}/ads`}> */}
            <CustomButton
              btnText={t("upload_ads")}
              // icon={<img alt="" src={docomentIcon} />}
              onClick={() => {
                if (!billboardStatus) {
                  handleClick();
                } else {
                  navigate(`/user/${billboardId}/ads`);
                }
              }}
            />
            {/* </Link> */}
          </Box>
        </Box>
      )}
    </>
  );
};
export default Dashboard;
