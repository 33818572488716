import { MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import English from "../../images/english.png";
import Spain from "../../images/spain.png";
import UAE from "../../images/uae.png";
import France from "../../images/france.png";
import Russia from "../../images/russia.png";

const LanguageSelector = () => {
  const { i18n } = useTranslation("en");

  useEffect(() => {
    // console.log(">>>>>", i18n.language);
    const selectedLanguage = localStorage.getItem("selectedLanguage");
    if (selectedLanguage === "ar") {
      document.body.classList.add("activeClass");
    } else {
      document.body.classList.remove("activeClass");
    }
  }, []);

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
    if (language === "ar") {
      document.body.classList.add("activeClass");
    } else {
      document.body.classList.remove("activeClass");
    }
    localStorage.setItem("selectedLanguage", language);
  };

  return (
    <Select
      className="languageSelector"
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      onChange={(e) => handleChangeLanguage(e.target.value)}
      value={i18n.language || "en"}
      defaultValue="en"
      displayEmpty
      MenuProps={{
        classes: { paper: "your-custom-class-name" },
      }}
      sx={{
        "& .MuiSelect-select": {
          background: "linear-gradient(110deg, #151313 0%, #323131 100%)",
          color: "#d5d5d5",
        },
        "& fieldset": { display: "none" },
        "&.MuiInputBase-root": {
          "& .MuiSvgIcon-root": { color: "#d5d5d5" },
        },
      }}
      IconComponent={KeyboardArrowDownIcon}
    >
      <MenuItem
        value=""
        disabled
        sx={{
          color: "#d5d5d5",
          background: "linear-gradient(110deg, #151313 0%, #323131 100%)",
          "&:hover": {
            background: "#4F4F4F",
          },
        }}
      >
        Select language
      </MenuItem>
      <MenuItem
        value="en"
        sx={{
          color: "#d5d5d5",
          background: "linear-gradient(110deg, #151313 0%, #323131 100%)",
          "&:hover": { background: "#4F4F4F" },
        }}
      >
        <img
          src={English}
          alt=""
          style={{ width: "20px", height: "20px", marginRight: "10px" }}
        />
        English
      </MenuItem>
      <MenuItem
        value="es"
        sx={{
          color: "#d5d5d5",
          background: "linear-gradient(110deg, #151313 0%, #323131 100%)",
          "&:hover": { background: "#4F4F4F" },
        }}
      >
        <img
          src={Spain}
          alt=""
          style={{ width: "20px", height: "20px", marginRight: "10px" }}
        />
        Spanish
      </MenuItem>
      <MenuItem
        value="ar"
        sx={{
          color: "#d5d5d5",
          background: "linear-gradient(110deg, #151313 0%, #323131 100%)",
          "&:hover": { background: "#4F4F4F" },
        }}
      >
        <img
          src={UAE}
          alt=""
          style={{ width: "20px", height: "20px", marginRight: "10px" }}
        />
        Arabic
      </MenuItem>
      <MenuItem
        value="fr"
        sx={{
          color: "#d5d5d5",
          background: "linear-gradient(110deg, #151313 0%, #323131 100%)",
          "&:hover": { background: "#4F4F4F" },
        }}
      >
        <img
          src={France}
          alt=""
          style={{ width: "20px", height: "20px", marginRight: "10px" }}
        />
        French
      </MenuItem>
      <MenuItem
        value="ru"
        sx={{
          color: "#d5d5d5",
          background: "linear-gradient(110deg, #151313 0%, #323131 100%)",
          "&:hover": { background: "#4F4F4F" },
        }}
      >
        <img
          src={Russia}
          alt=""
          style={{ width: "20px", height: "20px", marginRight: "10px" }}
        />
        Russian
      </MenuItem>
    </Select>
  );
};

export default LanguageSelector;
